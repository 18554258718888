import Auction from './Auction.json'
import BuyNow from './BuyNow.json'
import Common from './Common.json'
import Dispute from './Dispute.json'
import Listing from './Listing.json'
import Offerings from './Offerings'
import unsorted from './unsorted.json'

export default {
  Auction,
  Common,
  Dispute,
  Offerings,
  BuyNow,
  Listing,
  ...unsorted,
} as const
